const main = () => {
  const plausibleScript = document.createElement('script');
  plausibleScript.src = 'https://plausible.io/js/plausible.js';
  plausibleScript.setAttribute('async', '');
  plausibleScript.setAttribute('defer', '');
  plausibleScript.setAttribute('data-domain', window.location.host);
  document.head.appendChild(plausibleScript);
};

main();
